<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      statData: [
        { type: "line", title: "Pedidos", value: "-", icon: "bx-shopping-bag", },
        { type: "line", title: "Vendas Realizadas", value: "R$ -,--", icon: "bx-dollar-circle", },
        { type: "line", title: "Ticket Médio", value: "R$ -,--", icon: "bx-cart", },
        { type: "line", title: "Taxa de Conversão", value: "-%", icon: "bx-line-chart", },
      ]
    };
  },
  methods: {
    getTotals() {
      api
        .get("reports/totals")
        .then((response) => {
          if (response.data.status == "success") {
            this.statData = [
              { type: "line", title: "Pedidos", value: response.data.total.orders.toString(), icon: "bx-shopping-bag", },
              { type: "line", title: "Vendas Realizadas", value: this.$options.filters.currency(response.data.total.sales).toString(), icon: "bx-dollar-circle", },
              { type: "line", title: "Ticket Médio", value: this.$options.filters.currency(response.data.total.average).toString(), icon: "bx-cart", },
              { type: "line", title: "Taxa de Conversão", value: response.data.total.conversion.toString(), icon: "bx-line-chart", },
            ];
          }
        })
      },
  },
  mounted() {
    this.getTotals();
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="account.user && account.user.status && account.user.status == 'inactive'" class="bg-soft-danger text-dark rounded p-3 mb-4 d-flex align-items-center" tag="a" to="/settings">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
          <path
            d="M16.707 2.293A.996.996 0 0 0 16 2H8a.996.996 0 0 0-.707.293l-5 5A.996.996 0 0 0 2 8v8c0 .266.105.52.293.707l5 5A.996.996 0 0 0 8 22h8c.266 0 .52-.105.707-.293l5-5A.996.996 0 0 0 22 16V8a.996.996 0 0 0-.293-.707l-5-5zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z">
          </path>
        </svg>
      </div>
      <div class="ml-2">
        <strong>Atenção:</strong> A sua franquia ainda está inativa.
      </div>
    </router-link>

    <Banners></Banners>

    <div class="card">
      <div class="card-body d-flex justify-content-between">
        <h6 class="m-0">Seja bem-vindo à Área do Franqueado</h6>
        <h6 v-if="account.user && account.user.name" class="m-0 text-right">{{ account.user.name }}</h6>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>
  </Layout>
</template>